<template>
  <div class="container">
    <h3>Data Table</h3>
  </div>
  <DataTableComponent v-if="$store.state.SelectedDatabase" ></DataTableComponent>
  <div v-else class="alert alert-danger" role="alert">
    Select a valid database before!
  </div>
</template>
<script>
// @ is an alias to /src
import DataTableComponent from '@/components/DataTableComponent.vue'

export default {
  name: 'TableView',
  components: {
    DataTableComponent
  }
}
</script>
