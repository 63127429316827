<template>
  <nav aria-label="breadcrumb">
  <ol class="breadcrumb">
    <li class="breadcrumb-item">
      <router-link to="/">Home</router-link>
    </li>
    <li class="breadcrumb-item">
      <router-link to="/measurements">Measurements</router-link>
    </li>
    <li class="breadcrumb-item active" aria-current="page">
      <router-link to="/devices">Devices</router-link>
    </li>
    <li class="breadcrumb-item active" aria-current="page">
      <router-link to="/data_table">Table Values</router-link>
    </li>
  </ol>
</nav>
<router-view/>
</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
