<template>
  <div class="home">
    <div class="container">
      <!-- <img alt="Vue logo" src="../assets/logo.png"> -->
      <HomeComponent msg="Welcome to Mastro App"/>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import HomeComponent from '@/components/HomeComponent.vue'

export default {
  name: 'HomeView',
  components: {
    HomeComponent
  }
}
</script>
