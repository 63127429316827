<template>
  <div class="container">
    <h3>Devices</h3>
  </div>
  <DevicesComponent v-if="$store.state.SelectedDatabase" :database="database"></DevicesComponent>
  <div v-else class="alert alert-danger" role="alert">
    Select a valid database before!
  </div>
</template>
<script>
// @ is an alias to /src
import DevicesComponent from '@/components/DevicesComponent.vue'

export default {
  name: 'DevicesView',
  components: {
    DevicesComponent
  }
}
</script>
